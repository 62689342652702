//action types for home reducer
export const load_application = "LOAD_APPLICATION";
export const set_home_component_index = "SET_HOME_COMPONENT_INDEX";
export const set_teacher_uuid = "SET_TEACHER_UUID";
export const set_home_variable = "SET_HOME_VARIABLE";
export const set_password = "SET_PASSWORD";
export const log_in_to_app = "LOG_IN_TO_APP";
export const log_out = "LOG_OUT";
export const sign_up = "SIGN_UP";
export const forgot_password = "FORGOT_PASSWORD";
export const load_my_sessions = "LOAD_MY_SESSIONS";
export const get_config = "GET_CONFIG";
export const getStudents = "GET_STUDENTS";
export const update_status = "UPDATE_STATUS";
export const create_roadmap = "CREATE ROADMAP";
export const update_session_recap = "UPDATE SESSION RECAP";
export const update_roadmap_question = "UPDATE ROADMAP QUESTION";
export const update_roadmap = "UPDATE ROADMAP";
export const get_yoga_journey = "GET YOGA JOURNEY";

// action types for workshops

export const get_workshop_details = "GET_WORKSHOP_DETAILS";
export const get_workshop_details_wo_token = "GET_WORKSHOP_DETAILS_WO_TOKEN";
export const get_all_workshops = "GET_ALL_WORKSHOP";
export const get_all_workshops_wo_token = "GET_ALL_WORKSHOP_WO_TOKEN";

//action types for dashboard
export const load_dashboard_sessions = "LOAD_DASHBOARD_SESSIONS";
export const load_short_dashboard_sessions = "LOAD_SHORT_DASHBOARD_SESSIONS";
export const set_dashboard_variable = "SET_DASHBOARD_VARIABLE";
export const WITHDRAW_REPLACEMENT_REQEUEST = "WITHDRAW_REPLACEMENT_REQEUEST";
export const cancel_session = "CANCEL_SESSION";
export const load_student_details = "LOAD_STUDENT_DETAILS";
export const load_student_details_slug = "LOAD_STUDENT_DETAILS_SLUG";
export const get_session_update_slots = "GET_SESSION_UPDATE_SLOTS";
export const change_session_time = "CHANGE_SESSION_TIME";
export const schedule_session_with_student = "SCHEDULE_SESSION_WITH_STUDENT";
export const request_replacement = "REQUEST_REPLACEMENT";
export const get_session_participants = "GET_SESSION_PARTICIPANTS";
export const get_active_call_status = "GET_ACTIVE_CONSULT_CALL_STATUS";

//action types for availabilty reducer
export const get_my_availability = "GET_MY_AVAILABILITY";
export const set_availability_variable = "SET_AVAILABILITY_VARIABLE";
export const add_availability = "ADD_AVAILABILITY";
export const edit_availability = "EDIT_AVAILABILITY";
export const delete_availability = "DELETE_AVAILABILITY";
export const handle_error_from_api = 'HANDLE_ERROR_FROM_API';
export const my_session_v1 = 'MY_SESSION_V1';
export const my_session_data_v1 = 'MY_SESSION_DATA_V1';

//action types for profile reducer

export const get_teacher_details = "GET_TEACHER_DETAILS";
export const set_profile_variable = "SET_PROFILE_VARIABLE";

export const update_profile_details = "UPDATE_PROFILE_DETAILS";
export const update_profile_files = "UPDATE_PROFILE_FILES";

// action types for welcome reducer

export const set_welcome_variable = "SET_WELCOME_VARIABLE";
export const verify_email_token = "VERIFY_EMAIL_TOKEN";

//action types for zoom

export const set_zoom_variable = "SET_ZOOM_VARIABLE";
export const get_zoom_signature = "GET_ZOOM_SIGNATURE";
export const session_log_start = "SESSION_LOG_START";
export const session_log_end = "SESSION_LOG_END";
export const join_session = "JOIN_SESSION";

//action typed for loading reducer
export const set_loading_variable = "SET_LOADING_VARIABLE";

//action types for notifications
export const set_notification_variable = "SET_NOTIFICATION_VARIABLE";
export const get_notifications = "GET_NOTIFICATIONS";
export const get_new_notifications_summary = "GET_NEW_NOTIFICATIONS_SUMMARY";
export const update_notification_read_status =
  "UPDATE_NOTIFICATION_READ_STATUS";
export const get_announcements = "GET_ANNOUNCEMENTS";
export const read_announcement = "READ_ANNOUNCEMENT";

//action types for community classes
export const community_proposal_accept = "COMMUNITY_PROPOSAL_ACCEPT";
export const community_proposal_ignore = "COMMUNITY_PROPOSAL_IGNORE";
export const get_community_profile = "GET_COMMUNITY_PROFILE";
export const get_proposal_info = "GET_PROPOSAL_INFO";
export const propose_group_class = "PROPOSE_GROUP_CLASS";
export const is_community_class_proposals_create_allowed =
  "IS_COMMUNITY_CLASS_PROPOSAL_CREATE_ALLOWED";

// Request replacement
export const accept_session_replacement = "ACCEPT_SESSION_REPLACEMENT";
export const reject_session_replacement = "REJECT_SESSION_REPLACEMENT";
export const request_replacement_list = "REQUEST_REPLACEMENT_LIST";
export const impersonate_myt_user_teacher_consultancy =
  "IMPERSONATE_MYT_USER_TEACHER_CONSUNLANCY";

export const get_teacher_notification_preferences = 'GET_TEACHER_NOTIFICATION_PREFERENCES';
export const update_teacher_notification_preferences = 'UPDATE_TEACHER_NOTIFICATION_PREFERENCES'